import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Create", "title": "Add New Item", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Add")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { key: "ItemCode", attrs: { "label": "Item Code", "placeholder": "Type Item Code", "rules": "required", "span": 12, "form-item": "" }, on: { "change": function($event) {
      return _vm.storeValue("ItemCode", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "data-source": _vm.countries, "source": "countryId", "source-label": "country", "label": "Country", "placeholder": "Select Country", "rules": "required", "span": 12, "form-item": "" }, on: { "change": function($event) {
      return _vm.storeValue("CountryID", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateVolume",
  inject: ["crud", "redirectRoute"],
  mixins: [MyUtil],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      createForm: {},
      isLoading: false
    };
  },
  created() {
    this.getCountries();
    this.storeValue("UpdateType", "add");
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    submit() {
      this.isLoading = true;
      this.crud.submitEntity("replace").then(() => {
        this.crud.fetchList();
        this.visible = false;
        this.isLoading = false;
      }).catch(() => this.isLoading = false);
    },
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    },
    onCancel() {
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateTypeAItems = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.include-type-a", "api-url": _vm.apiUrl, "resource-id-name": "itemCode", "redirect-route": "/web-analytics/include-type-a" } }, [_c("create-type-a-items")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateTypeAItems
  },
  data() {
    return {
      apiUrl,
      CreateTypeAItems
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
